import React, { useReducer, createContext } from 'react'

const initialState = {
  isSidebarOpen: false,
  openSidebar: () => {},
  closeSidebar: () => {},
}

export const AppContext = createContext<typeof initialState>(initialState)

type Action = { type: 'open_sidebar' } | { type: 'close_sidebar' }

const reducer = (state: typeof initialState, action: Action) => {
  switch (action.type) {
    case 'open_sidebar': {
      return { ...state, isSidebarOpen: true }
    }
    case 'close_sidebar': {
      return { ...state, isSidebarOpen: false }
    }
    default: {
      return state
    }
  }
}

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const openSidebar = () => {
    dispatch({ type: 'open_sidebar' })
  }

  const closeSidebar = () => {
    dispatch({ type: 'close_sidebar' })
  }

  return (
    <AppContext.Provider value={{ ...state, openSidebar, closeSidebar }}>
      {children}
    </AppContext.Provider>
  )
}
